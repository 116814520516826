import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "C:/Users/chatp/Projects/farming-season/doc/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "ร้านค้าเบนจามิน"
    }}>{`ร้านค้าเบนจามิน`}</h1>
    <hr></hr>
    <h3 {...{
      "id": "เครื่องอำนวยความสะดวก"
    }}>{`เครื่องอำนวยความสะดวก`}</h3>
    <p>{`เครื่องอำนวยความสะดวกในฟาร์ม ที่จะช่วยให้ฟาร์มลดขั้นตอนการทำงานลง สามารถซื้อได้จากร้านค้าภายในเกม`}</p>
    <p><img alt="mat" src={require("./public/images/fa-1.png")} /></p>
    <ul>
      <li parentName="ul">{`เครื่องเก็บไข่อัตโนมัติ`}</li>
      <li parentName="ul">{`เครื่องรีดนมวัวอัติโนมัติ`}</li>
      <li parentName="ul">{`เครื่องตัดขนแกะอัตโนมัติ`}</li>
      <li parentName="ul">{`เครื่องรดน้ำอัตโนมัติ`}</li>
    </ul>
    <p><img alt="mat" src={require("./public/images/fa-2.png")} /></p>
    <p>{`เครื่องอำนวยความสะดวกมีอายุการใช้งาน 10 ครั้ง ถ้าคุณต้องการใช้งานต่อ คุณจะต้องซ่อมแซมเครื่องมือของคุณก่อน จึงจะสามารถใช้งานได้อีกครั้ง`}</p>
    <p><img alt="mat" src={require("./public/images/fa-3.png")} /></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      